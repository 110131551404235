<template>
  <BaseLayout>
    <HighlightedSection
        :title="translation('error_404-title')"
        :text="translation('error_404-text')"
        :size="'xxs'"
        :color="'none'"
    >
      <BaseButton
          :is-link="true"
          :full-width="true"
          href="/"
      >
        {{ translation('error_404-button') }}
      </BaseButton>
    </HighlightedSection>
  </BaseLayout>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import BaseLayout from '@/components/BaseLayout.vue'
import HighlightedSection from "@/components/HighlightedSection";
import codeBooksMixin from "@/mixins/codeBooksMixin";

export default {
  mixins: [codeBooksMixin],
  components: {
    BaseButton,
    HighlightedSection,
    BaseLayout
  }
}
</script>
